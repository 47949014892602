import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const translationResources = {
	ru: {
			translation: {
					button:{
							back: "Назад",
							enter: "Войти",
					},
					placeholder: {
							password: "Пароль",
					},
					Login: {
							welcome: "Добро пожаловать в систему! Введите данные для входа.",
							error: {
									default: "Ошибка входа",
									emptyPassword: "Пожалуйста, введите пароль!",
							},
					},
					NotFound: {
							subTitle: 'Простите, но запрошенная страница не существует',
					},
					Balance: {
							title: "Текущий баланс",
							tariff: {
									nameless: "Без имени",
									channels: "Каналов",
									requests: "Запросов",
									words: "Слов",
									isActive: "активен",
									isExpired: "истек",
									activeTill: "до",
									expiredAt: "после",
							},
							empty: "Пусто",
							requestError: "С данным запросом возникли проблемы.",
					},
					Post: {
							yes: "да",
							no: "нет",
							search: {
								placeholder: "ID поста - t.me/username/123 или t.me/c/1256804429/1230",
								button: "Поиск",
									empty: "Введите запрос и нажмите \"Поиск\"",
							},
							table: {
								property: "Свойство",
								value: "Значение",
							},
							settings: {
									title: "Что будем запрашивать?",
									titleHelp: "запросы на получение каких данных необходимо отправить",
									getInfo: "Информацию публикации",
									getStat: "Статистику публикации",
							},
							publication: {
									id: "ID в TGStat",
									date: "Дата",
									views: "Просмотры",
									link: "Ссылка",
									channel_id: "ID канала",
									forwarded_from: "Переслано из",
									is_deleted: "Удалено",
									deleted_at: "Дата удаления",
									text: "Текст",
									viewsCount: "Просмотры",
									sharesCount: "Shares",
									commentsCount: "Комментарии",
									reactionsCount: "Реакции",
									forwardsCount: "Forwards",
									mentionsCount: "Упоминания",
									group_id: "ID группы",
									media: {
											media_type: "Тип медиаконтента",
											caption: "Название медиаконтента",
											file_size: "Размер медиаконтента",
											file_url: "URL медиаконтента",
											file_thumbnail_url: "URL иконки медиаконтента",
											type: "Подтип медиаконтента",
											site_name: "Название сайта медиаконтента",
											title: "Титул медиаконтента",
											description: "Описание медиаконтента",
											author: "Автор медиаконтента",
											file_name: "Название файла медиаконтента",
											long: "Долгота",
											lat: "Широта",
											period: "Период (место встречи)",
											venue_type: "Тип (место встречи)",
											venue_id: "ID (место встречи)",
											provider: "Провайдер (место встречи)",
											address: "Адрес",
									},
									mediaType: {
											mediaDocument: "Документ",
											mediaWebPage: "Web страница",
											mediaPhoto: "Фотография",
											mediaGeo: "Геолокация",
											mediaContact: "Контакт",
											mediaVenue: "Место встречи",
											mediaGeoLive: "Геолокация live",
											mediaGame: "Игра",
											mediaInvoice: "Счет",
											unknown: "Неизвестный",
									}
							},
							NotFound: {
									subTitle: "Пост не найден.",
							},
							requestError: "С данным запросом возникли проблемы.",
					},
					Channel: {
							yes: "да",
							no: "нет",
							search: {
									placeholder: "ID канала - @username или t.me/username",
									button: "Поиск",
									empty: "Введите запрос и нажмите \"Поиск\"",
							},
							table: {
									property: "Свойство",
									value: "Значение",
							},
							settings: {
									title: "Что будем запрашивать?",
									titleHelp: "запросы на получение каких данных необходимо отправить",
									getInfo: "Информацию канала",
									getStat: "Статистику канала",
							},
							channel: {
									id: "ID канала в TGStat",
									tg_id: "ID канала в Telegram",
									link: "Ссылка на канал",
									title: "Название канала",
									username: "Username канала",
									peer_type: "Тип",
									about: "О канале",
									category: "Категория TGStat",
									country: "Страна",
									language: "Язык",
									image100: "image 100x100",
									image640: "image 640x640",
									participants_count: "Количество подписчиков",
									avg_post_reach: "Средний охват публикации",
									adv_post_reach_12h: "Средний рекламный охват публикации за 12 часов",
									adv_post_reach_24h: "Средний рекламный охват публикации за 24 часа",
									adv_post_reach_48h: "Средний рекламный охват публикации за 48 часов",
									err_percent: "Вовлеченность",
									err24_percent: "Вовлеченность в просмотр 24 часа",
									er_percent: "Вовлеченность во взаимодействие",
									daily_reach: "Cуммарный дневной охват",
									ci_index: "Индекс цитирования",
									mentions_count: "Упоминаний канала",
									forwards_count: "Репостов",
									mentioning_channels_count: "Каналов, упоминающих данный канал",
									posts_count: "Количество неудаленных публикаций",
									channel: "канал",
									chat: "чат",
									unknown: "неизвестный",
							},
							NotFound: {
									subTitle: "Канал не найден.",
							},
							requestError: "С данным запросом возникли проблемы.",
					},
					Publication: {
							"commentsCount_zero": "{{count}} комментариев",
							"commentsCount_one": "{{count}} комментарий",
							"commentsCount_few": "{{count}} комментария",
							"commentsCount_many": "{{count}} комментариев",
							"reactionsCount_zero": "{{count}} реакций",
							"reactionsCount_one": "{{count}} реакция",
							"reactionsCount_few": "{{count}} реакции",
							"reactionsCount_many": "{{count}} реакций",
					},
					ExportPostStats: {
							copyToClipboardForAnalytics: "Для аналитики",
							copyToClipboard: {
									action: "Скопировать в буфер обмена",
									successMessage: {
											title: "Готово",
											message:  "Данные скопированы в буфер",
									},
									errorMessage: {
											title: "Ошибка",
											message:  "Не удалось скопировать данные в буфер",
									},
							},
							search: {
									placeholder: "Публикации в формате t.me/username/123 или t.me/c/1256804429/1230",
									button: "Поиск",
									empty: "Введите запрос и нажмите \"Поиск\"",
									pleaseRead: "Рекомендуем вам ознакомиться с",
									manual: "руководством пользователя",
							},
							searchArray: {
									title: "Список публикаций",
									titleHelp: "введенные вами и принятые системой публикации",
									empty: "здесь пока ничего нет",
							},
							settings: {
									title: "Что будем запрашивать?",
									titleHelp: "запросы на получение каких данных необходимо отправить",
							},
							publication: {
									status: "Статус публикации",
									isActive: "Активна",
									isDeleted: "Удалена",
									isNotFound: "Не найдена",
									publicationDate: "Дата выхода",
									publicationTime: "Время выхода",
									deletionDate: "Дата удаления",
									deletionTime: "Время удаления",
									viewsCount: "Показы",
									link: "URL публикации",
									likes: "Likes",
									shares: "Shares",
									comments: "Комментарии",
							},
					},
					ExportChannelStats: {
							copyToClipboardForPublicsRegister: "Для реестра пабликов",
							copyToClipboard: {
									action: "Скопировать в буфер обмена",
									successMessage: {
											title: "Готово",
											message:  "Данные скопированы в буфер",
									},
									errorMessage: {
											title: "Ошибка",
											message:  "Не удалось скопировать данные в буфер",
									},
							},
							search: {
									placeholder: "Каналы в формате t.me/username или t.me/c/1256804429",
									button: "Поиск",
									empty: "Введите запрос и нажмите \"Поиск\"",
									pleaseRead: "Рекомендуем вам ознакомиться с",
									manual: "руководством пользователя",
							},
							searchArray: {
									title: "Список каналов",
									titleHelp: "введенные вами и принятые системой каналы",
									empty: "здесь пока ничего нет",
							},
							settings: {
									title: "Что будем запрашивать?",
									titleHelp: "запросы на получение каких данных необходимо отправить",
							},
							channel: {
									status: "Статус канала",
									isNotFound: "Не найден",
									isActive: "Активен",
									link: "URL канала",
							},
					},
			}
	}
}

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        fallbackLng: "ru",
				react: {
					useSuspense: false,
				},
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: translationResources,
    });

export default i18n;
